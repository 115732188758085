import $ from 'jquery';
import 'slick-carousel';

function turbolinksCheck() {
  return (typeof Turbolinks == 'undefined') ? 'DOMContentLoaded' : 'turbolinks:load';
}

let initializer = turbolinksCheck();

function getSlickyWithIt() {
  if ($('.testimonials').length) {
    $('.testimonials').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      fade: true,
      dotsClass: 'testimonial-dots'
    });
  }
}

document.addEventListener(
  initializer,
  getSlickyWithIt
);
